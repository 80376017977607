var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _vm.details
        ? _c(
            "v-dialog",
            {
              attrs: { width: "550", persistent: "" },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "fixed",
                    "max-width": "550px",
                    "margin-top": "-12%",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "text-h5",
                          staticStyle: { "margin-bottom": "-36px" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "20px !important",
                                "font-weight": "bold",
                              },
                              attrs: { id: "dialog-title" },
                            },
                            [_vm._v(_vm._s(_vm.details.assuntoModelo))]
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: {
                                position: "absolute",
                                left: "90%",
                                top: "0.7%",
                                width: "32px",
                                height: "32px",
                              },
                              attrs: { cols: "1" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.close()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { staticClass: "m-1", attrs: { align: "center" } },
                            [
                              _c(
                                "div",
                                { attrs: { id: "message-content" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "bg-sub-title",
                                      attrs: { cols: "12", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                            "margin-bottom": "-1px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.message.establishmentName
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c("div", { staticClass: "col" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("cnpj")(_vm.message.cnpj)
                                              )
                                            ),
                                          ]),
                                          _c("v-spacer"),
                                          _c(
                                            "div",
                                            { staticClass: "col" },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("mdi-calendar")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      _vm.details.dataExpiracao
                                                    )
                                                  )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.details.corpoModelo
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { staticStyle: { "margin-top": "-30px" } },
                            [
                              _c("v-col", { attrs: { cols: "6" } }),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", align: "right" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        id: "IdBtnClose",
                                      },
                                      on: { click: _vm.close },
                                    },
                                    [_vm._v("Fechar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }