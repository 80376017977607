<template>
  <v-row justify="center">
    <v-dialog v-model="show" width="550" v-if="details" persistent>
      <div style="position: fixed; max-width: 550px; margin-top: -12%">
        <v-card>
          <v-card-title class="text-h5" style="margin-bottom: -36px">
            <p id="dialog-title" style="font-size: 20px !important; font-weight: bold">{{ details.assuntoModelo }}</p>
            <v-col cols="1" style="position: absolute; left: 90%; top: 0.7%; width: 32px; height: 32px">
              <v-btn icon @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row align="center" class="m-1">
              <div id="message-content">
                <v-col cols="12" sm="12" class="bg-sub-title">
                  <p style="font-weight: bold; margin-bottom: -1px">{{ message.establishmentName }}</p>
                  <div class="row">
                    <div class="col">{{ message.cnpj | cnpj }}</div>
                    <v-spacer />
                    <div class="col"><v-icon small>mdi-calendar</v-icon> {{ details.dataExpiracao | date }}</div>
                  </div>
                </v-col>
                <div v-html="details.corpoModelo"></div>
              </div>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row style="margin-top: -30px">
              <v-col cols="6"></v-col>
              <v-col cols="6" align="right">
                <v-btn color="primary" @click="close" id="IdBtnClose">Fechar</v-btn></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ModalMessageFull',
  props: ['details', 'message'],

  data() {
    return {
      show: false,
      integra: false,
      detalhe: {
        assunto: '',
        corpoModelo: '',
        dataExpiracao: null,
      },
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
};
</script>
<style>
.bg-sub-title {
  background-color: #f9fafb;
}
#message-content {
  overflow: auto;
  max-height: 300px;
  padding: 9px;
}
#dialog-title {
  max-width: 90%;
  font-size: 20px !important;
}
#IdBtnClose :hover {
  background: #e3ffee;
  border-radius: 3px;
  width: 168%;
  height: 300%;
  color: var(--v-primary-base);
}
</style>
